import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { HeaderBasicAuthorizationService } from '../../../../_shared/services/header-basic-authorization.service';
import { ApiEnum } from '../../../../_shared/enums/api.enum';
import { environment } from '../../../../../environments/environment';
import { StorageService } from 'app/_shared/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class EstadisticaBonoService {

    constructor(
        private http: HttpClient,
        private headerBasicAuthorization: HeaderBasicAuthorizationService,
        private storageService: StorageService,
    ) { }

    public reporteEstadisticaBono(EstadisticaBono) {
        return this.http.post(`${environment.api.WS_GCH.url}/EstadisticaBono/ReporteEstadisticaBono`, EstadisticaBono,
            { headers: this.headerBasicAuthorization.get(ApiEnum.WS_GCH) })
            .pipe(map(response => response));
    }

    public guardarEstadisticaBono(Tipo) {
        let model = {
            Estadistica: 0,
            Tipo,
            DNI: this.storageService.get("DNI"),
        }
        this.guardar(model).subscribe((response) => { });
    }

    public guardar(EstadisticaBono) {
        return this.http.post(`${environment.api.WS_GCH.url}/EstadisticaBono/Guardar`, EstadisticaBono,
            { headers: this.headerBasicAuthorization.get(ApiEnum.WS_GCH) })
            .pipe(map(response => response));
    }


}